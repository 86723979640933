import React, { useContext, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import { AddedToMarketplace } from "../UserProfile/AddedToMarketplace";
import { YourBids } from "../UserProfile/YourBids";
import Marketplace from "./Marketplace";

const menu = [
  {
    menuItem: "Marketplace",
    key: 1,
  },
  {
    menuItem: "My Bids",
    key: 2,
  },
  {
    menuItem: "My Auctions",
    key: 3,
  },
];

const MarketplacePage = () => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [activeTab, setActiveTab] = useState(1);

  const renderContent = () => {
    if (activeTab === 1) return <Marketplace />;
    if (activeTab === 2)
      return (
        <div style={{ width: "80%", margin: "20px auto" }}>
          <YourBids />
        </div>
      );
    if (activeTab === 3)
      return (
        <div style={{ width: "80%", margin: "20px auto" }}>
          <AddedToMarketplace />
        </div>
      );
  };
  return (
    <>
      <div class="marquee marquee--hover-pause">
        <div class="marquee__content">
          <p>
            ⚠️ Now all account require 10-quickplays before queue'ing ranked.
            Search for Ranked-ready accounts to skip the wait.⚠️
          </p>
          <p>
            ⚠️ Now all account require 10-quickplays before queue'ing ranked.
            Search for Ranked-ready accounts to skip the wait.⚠️
          </p>
          <p>
            ⚠️ Now all account require 10-quickplays before queue'ing ranked.
            Search for Ranked-ready accounts to skip the wait.⚠️
          </p>
        </div>

        <div aria-hidden="true" class="marquee__content">
          <p>
            ⚠️ Now all account require 10-quickplays before queue'ing ranked.
            Search for Ranked-ready accounts to skip the wait.⚠️
          </p>
          <p>
            ⚠️ Now all account require 10-quickplays before queue'ing ranked.
            Search for Ranked-ready accounts to skip the wait.⚠️
          </p>
          <p>
            ⚠️ Now all account require 10-quickplays before queue'ing ranked.
            Search for Ranked-ready accounts to skip the wait.⚠️
          </p>
        </div>
      </div>
      <Segment basic className="marketplace-page">
        <div style={{ textAlign: "center" }}>
          <Button.Group>
            {menu.map((tab, id) => {
              return (
                <Button
                  key={id}
                  onClick={() => setActiveTab(tab.key)}
                  primary={tab.key === activeTab}
                  className={
                    isDarkMode && tab.key !== activeTab
                      ? "marketplace-tab-inactive"
                      : ""
                  }
                >
                  {tab.menuItem}
                </Button>
              );
            })}
          </Button.Group>
        </div>
        {renderContent()}
      </Segment>
    </>
  );
};

export default MarketplacePage;
