import React, { useCallback, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Input,
  Menu,
} from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import { getFilter } from "../../utils";
import ChampionSkinShardSearch from "../../components/ChampionSkinShardSearch";
import CustomDropDown from "../../components/CustomDropdown";
import InfoPopup from "../../components/InfoPopup";
import RankSearch from "../../components/RankSearch";
import SkinShardSearch from "../../components/SkinShardSearch";
const regions = require("../../json/regions.json");
const roles = require("../../json/roles.json");
const lanes = require("../../json/lanes.json");
const beChoices = require("../../json/beChoices.json");
const oeChoices = require("../../json/oeChoices.json");
const accountTypes = require("../../json/accountTypes.json");
const flashKeys = require("../../json/flashKeys.json");
const sortChoices = require("../../json/sortChoices.json");

const useQuery = () => new URLSearchParams(useLocation().search);

export default function MarketplaceSidebar({ loading, onChange, path }) {
  const query = useQuery();
  const history = useHistory();
  const [clearable, setClearable] = React.useState(query.toString());

  const { isDarkMode } = useContext(DarkModeContext);

  const handlePremiumChange = useCallback((query, history, value, path) => {
    if (value) query.set("premium", "true");
    else query.delete("premium");
    history.push({ pathname: path, search: query.toString() });
  }, []);

  const handleAccountTypeChange = useCallback(
    (query, history, accountType, path) => {
      if (accountType) query.set("account-type", accountType);
      else query.delete("account-type");
      if (accountType !== "ranked") {
        query.delete("rank");
        query.delete("division");
      }
      query.delete("flash-key");
      history.push({ pathname: path, search: query.toString() });
    },
    [],
  );

  useEffect(() => {
    // backward compatibility for account-type=premium
    if (query.get("account-type") === "premium") {
      handleAccountTypeChange(query, history, null, path);
      handlePremiumChange(query, history, true, path);
      onChange(getFilter(query));
    }
  }, [
    query,
    history,
    onChange,
    handleAccountTypeChange,
    handlePremiumChange,
    path,
  ]);

  useEffect(() => {
    setClearable(query.toString());
  }, [query]);

  const handleBlueEssenceChange = (
    query,
    history,
    blueEssenceStart,
    blueEssenceEnd,
  ) => {
    if (blueEssenceStart) query.set("blue-essence-start", blueEssenceStart);
    else query.delete("blue-essence-start");
    if (blueEssenceEnd) query.set("blue-essence-end", blueEssenceEnd);
    else query.delete("blue-essence-end");

    history.push({ pathname: path, search: query.toString() });
  };

  const handleOrangeEssenceChange = (query, history, orangeEssenceChoice) => {
    const orangeEssence = orangeEssenceChoice.split("-");
    if (orangeEssence[0]) query.set("orange-essence-start", orangeEssence[0]);
    else query.delete("orange-essence-start");
    if (orangeEssence[1]) query.set("orange-essence-end", orangeEssence[1]);
    else query.delete("orange-essence-end");

    history.push({ pathname: path, search: query.toString() });
  };

  const handleRegionChange = (query, history, region) => {
    if (region) query.set("region", region);
    else query.delete("region");

    history.push({ pathname: path, search: query.toString() });
  };

  const handleSortChange = (query, history, sort) => {
    query.delete("lane");
    query.delete("role");
    query.delete("champion");
    query.delete("rank-order");
    if (sort) query.set("sort", sort);
    else query.delete("sort");

    history.push({ pathname: path, search: query.toString() });
  };

  const handleRoleChange = (query, history, role) => {
    if (role) query.set("role", role);
    else query.delete("role");

    history.push({ pathname: path, search: query.toString() });
  };

  const handleLaneChange = (query, history, lane) => {
    if (lane) query.set("lane", lane);
    else query.delete("lane");
    history.push({ pathname: path, search: query.toString() });
  };

  const handleRankSortChange = (query, history, order) => {
    if (order) query.set("rank-order", order);
    else query.delete("rank-order");
    history.push({ pathname: path, search: query.toString() });
  };

  const handleAccountTierChange = (query, history, accountTier) => {
    if (accountTier) query.set("account-tier", accountTier);
    else query.delete("account-tier");
    history.push({ pathname: path, search: query.toString() });
  };

  const handleFlashKeyChange = (query, history, flashKey) => {
    if (flashKey !== "any") query.set("flash-key", flashKey);
    else query.delete("flash-key");
    history.push({ pathname: path, search: query.toString() });
  };

  const renderSortChoice = (sort) => {
    switch (sort) {
      case "lane":
        return (
          <Menu.Item>
            <div className="search-item">
              <CustomDropDown
                selection
                clearable
                value={query.get("lane")}
                options={Object.values(lanes)}
                placeholder="Choose lane"
                onChange={(e, { value }) => {
                  handleLaneChange(query, history, value);
                  onChange(getFilter(query));
                }}
              />
              <InfoPopup
                description={
                  query.get("lane")
                    ? `Showing accounts with ${query.get(
                        "lane",
                      )} skin shards first.`
                    : "Choose a lane."
                }
              />
            </div>
          </Menu.Item>
        );
      case "rank":
        return (
          <Menu.Item>
            <div className="search-item">
              <CustomDropDown
                selection
                clearable
                value={query.get("rank-order")}
                options={Object.values([
                  { key: "HighToLow", text: "High to Low", value: "HighToLow" },
                  {
                    key: "Low To High",
                    text: "Low to High",
                    value: "LowToHigh",
                  },
                ])}
                placeholder="Sort by rank"
                onChange={(e, { value }) => {
                  handleRankSortChange(query, history, value);
                  onChange(getFilter(query));
                }}
              />
              <InfoPopup
                description={
                  query.get("rank-order")
                    ? `Sort accounts by rank in ${query.get(
                        "rank-order",
                      )} order.`
                    : "Choose the order."
                }
              />
            </div>
          </Menu.Item>
        );
      case "role":
        return (
          <Menu.Item>
            <div className="search-item">
              <CustomDropDown
                selection
                clearable
                value={query.get("role")}
                options={Object.values(roles)}
                placeholder="Choose role"
                onChange={(e, { value }) => {
                  handleRoleChange(query, history, value);
                  onChange(getFilter(query));
                }}
              />
              <InfoPopup
                description={
                  query.get("role")
                    ? `Showing accounts with ${query.get(
                        "role",
                      )} skin shards first.`
                    : "Choose a role."
                }
              />
            </div>
          </Menu.Item>
        );
      case "champion":
        return (
          <Menu.Item>
            <ChampionSkinShardSearch
              input={
                <Input
                  fluid
                  type="text"
                  className={`purchased-product-search${
                    isDarkMode ? " dark" : ""
                  }`}
                  placeholder="Type to add one or more champions"
                />
              }
              queryKey="champion"
              onChange={(champions) => {
                query.delete("champion");
                champions.forEach((champion) =>
                  query.append("champion", champion),
                );
                history.push({
                  pathname: path,
                  search: query.toString(),
                });
                onChange(getFilter(query));
              }}
            />
          </Menu.Item>
        );
      default:
        return null;
    }
  };

  return (
    <div className="sidebar-menu-wrapper">
      <div className="sidebar-header ">
        <Header inverted={isDarkMode} style={{ marginBottom: 0 }}>
          Search Accounts
        </Header>
        {clearable && (
          <Button
            inverted={isDarkMode}
            compact
            basic
            onClick={() => {
              history.push(path);
              onChange("");
            }}
          >
            Clear Filters <Icon name="close" style={{ margin: "0px" }} />
          </Button>
        )}
      </div>
      <div className="sidebar-menu">
        <Menu vertical inverted={isDarkMode} fluid secondary loading={loading}>
          <Menu.Item>
            <Form inverted={isDarkMode}>
              <Form.Group className="account-type-radio">
                {accountTypes.map((accountType) => (
                  <Form.Checkbox
                    radio
                    key={accountType.text}
                    label={accountType.text}
                    checked={
                      (query.get("account-type") || "any") === accountType.value
                    }
                    onChange={() => {
                      handleAccountTypeChange(
                        query,
                        history,
                        accountType.value,
                        path,
                      );
                      onChange(getFilter(query));
                    }}
                  />
                ))}
                <Form.Checkbox
                  radio
                  label="Ranked Ready"
                  className="ranked-ready-radio"
                  checked={
                    (query.get("account-type") || "any") === "ranked-ready"
                  }
                  onChange={() => {
                    handleAccountTypeChange(
                      query,
                      history,
                      "ranked-ready",
                      path,
                    );
                    onChange(getFilter(query));
                  }}
                />
              </Form.Group>
            </Form>
          </Menu.Item>
          {["handleveled", "ranked"].includes(query.get("account-type")) && (
            <Menu.Item>
              <Menu.Header>Flash Key</Menu.Header>
              <Form inverted={isDarkMode}>
                <Form.Group>
                  {flashKeys.map((flashKey) => (
                    <Form.Checkbox
                      radio
                      label={flashKey.text}
                      checked={
                        (query.get("flash-key") || "any") === flashKey.value
                      }
                      onChange={() => {
                        handleFlashKeyChange(query, history, flashKey.value);
                        onChange(getFilter(query));
                      }}
                    />
                  ))}
                </Form.Group>
              </Form>
            </Menu.Item>
          )}
          {query.get("account-type") === "ranked" && (
            <RankSearch onChange={onChange} path={path} />
          )}

          <Menu.Item>
            <Menu.Header>Any Skin/ Skins of your choice</Menu.Header>
            <SkinShardSearch
              input={
                <Input
                  fluid
                  type="text"
                  className={`purchased-product-search${
                    isDarkMode ? " dark" : ""
                  }`}
                  placeholder="Find your favorite skin"
                />
              }
              queryKey="skin-shard"
              onChange={(skins) => {
                query.delete("skin-shard");
                skins.forEach((skin) => query.append("skin-shard", skin));
                history.push({
                  pathname: path,
                  search: query.toString(),
                });
                onChange(getFilter(query));
              }}
              info
            />
          </Menu.Item>
        </Menu>
      </div>
      <div className="sidebar-menu">
        <Menu
          vertical
          inverted={isDarkMode}
          fluid
          secondary
          loading={loading}
          className="sidebar-menu"
        >
          <Menu.Item>
            <Form inverted={isDarkMode} className="account-tier-form">
              <div className="search-item">
                <Checkbox
                  label="Mythic"
                  checked={query.get("account-tier") === "MYTHIC"}
                  onChange={(e, { checked }) => {
                    handleAccountTierChange(
                      query,
                      history,
                      checked ? "MYTHIC" : null,
                    );
                    onChange(getFilter(query));
                  }}
                />
                <InfoPopup
                  description={
                    "Filter accounts containing mythic skins. Mythic skins are more expensive."
                  }
                />
              </div>
              <div className="search-item">
                <Checkbox
                  label="Premium"
                  checked={query.get("premium") === "true"}
                  onChange={(e, { checked }) => {
                    handlePremiumChange(query, history, checked, path);
                    onChange(getFilter(query));
                  }}
                />
                <InfoPopup
                  description={
                    "Filter premium accounts. Premium accounts have significantly lower banrate."
                  }
                />
              </div>
            </Form>
          </Menu.Item>
          <Menu.Item>
            <div className="search-item">
              <CustomDropDown
                selection
                clearable
                value={query.get("blue-essence-start")}
                options={Object.values(beChoices)}
                placeholder="Blue Essence"
                onChange={(e, { value }) => {
                  handleBlueEssenceChange(query, history, value);
                  onChange(getFilter(query));
                }}
              />
              <InfoPopup
                description={
                  query.get("blue-essence-start")
                    ? `Filtering accounts with minimum ${query.get(
                        "blue-essence-start",
                      )} blue essence.`
                    : "Filter accounts containing given blue essence."
                }
              />
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="search-item">
              <CustomDropDown
                selection
                clearable
                value={query.get("region")}
                options={Object.values(regions)}
                placeholder="Region"
                onChange={(e, { value }) => {
                  handleRegionChange(query, history, value);
                  onChange(getFilter(query));
                }}
              />
              <InfoPopup
                description={
                  query.get("region")
                    ? `Filtering accounts in ${query.get("region")} region.`
                    : "Filter accounts from given region."
                }
              />
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="search-item">
              <CustomDropDown
                selection
                clearable
                value={
                  (query.get("orange-essence-start") &&
                    query.get("orange-essence-end") &&
                    `${query.get("orange-essence-start")}-${query.get(
                      "orange-essence-end",
                    )}`) ||
                  query.get("orange-essence-start")
                }
                options={Object.values(oeChoices)}
                placeholder="Orange Essence"
                onChange={(e, { value }) => {
                  handleOrangeEssenceChange(query, history, value);
                  onChange(getFilter(query));
                }}
              />
              <InfoPopup
                description={
                  query.get("orange-essence-start") &&
                  query.get("orange-essence-end")
                    ? `Filtering accounts containing ${query.get(
                        "orange-essence-start",
                      )} - ${query.get("orange-essence-end")} orange essence.`
                    : "Filter accounts containing given orange essence."
                }
              />
            </div>
          </Menu.Item>
        </Menu>
      </div>
      <div className="sidebar-menu">
        <Menu vertical inverted={isDarkMode} fluid secondary loading={loading}>
          <Menu.Item>
            <Menu.Header>Sort By</Menu.Header>
            <div className="search-item">
              <CustomDropDown
                clearable
                selection
                value={query.get("sort")}
                options={Object.values(sortChoices)}
                placeholder="Sort by champion skins, roles, etc"
                onChange={(e, { value }) => {
                  handleSortChange(query, history, value);
                }}
              />
              <InfoPopup
                description={
                  query.get("sort")
                    ? `Sorting accounts based on selected ${query.get(
                        "sort",
                      )}(s).`
                    : "Sort your results by given options."
                }
              />
            </div>
          </Menu.Item>
          {renderSortChoice(query.get("sort"))}
        </Menu>
      </div>
    </div>
  );
}
