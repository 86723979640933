const displayName = process.env.REACT_APP_DISPLAY_NAME || "Lolaccshop";
const ownerName = process.env.REACT_APP_OWNER_NAME || "SMURFSKINS S.L.";
const ownerLocation =
  process.env.REACT_APP_OWNER_LOCATION ||
  "Carrer Miravet 46, El Pinell de Brai, 43594, Tarragona";
const registeredLocation =
  process.env.REACT_APP_REGISTERED_LOCATION || "Spain";
const companyNumber = process.env.REACT_APP_COMPANY_NUMBER || "47861542Z";
const website = process.env.REACT_APP_WEBSITE || "https://www.lolaccshop.com";
const supportEmail =
  process.env.REACT_APP_SUPPORT_EMAIL || "support@lolaccshop.com";
const disableElitepvpersBacklink =
  process.env.REACT_APP_DISABLE_ELITEPVPERS_BACKLINK || "0" === "1";
const discordLink = process.env.REACT_APP_DISCORD_LINK;
const discordFAQLink = process.env.REACT_APP_DISCORD_FAQ_LINK;
const leagueShopMode = process.env.REACT_APP_LEAGUE_SHOP_MODE || "smurfskins";
const allowDarkMode = (process.env.REACT_APP_ALLOW_DARK_MODE || "0") === "1";
const mythicAccountsLink =
  "https://www.lolaccshop.com/buy-account/?account-tier=MYTHIC";
const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const twitterLink = process.env.REACT_APP_TWITTER_LINK;
const instagramLink = process.env.REACT_APP_INSTAGRAM_LINK;
const tiktokLink = process.env.REACT_APP_TIKTOK_LINK;
const useSkinThumbail = (process.env.REACT_APP_USE_THUMBNAIL || "0") === "1";
const skinThumbnailLink =
  "https://raw.communitydragon.org/14.9/plugins/rcp-be-lol-game-data/global/default/v1/champion-tiles/";
const discordWidgetId = process.env.REACT_APP_DISCORD_WIDGET_ID;

const minimumStripeCheckout = 1.0;
const minimumCreditCardCheckout = 0.5;

const rankedValues = [
  "IRON",
  "BRONZE",
  "SILVER",
  "GOLD",
  "PLATINUM",
  "EMERALD",
  "DIAMOND",
  "MASTER",
  "GRANDMASTER",
  "CHALLENGER",
];

const countryCodes = require("./json/countryCodes.json");

const countryOptions = countryCodes.map((country) => {
  const countryOption = {
    key: country.code,
    content: country.name,
    text: country.dial_code,
  };

  if (country.code !== "others") {
    countryOption.flag = country.code;
    countryOption.label = country.dial_code;
    countryOption.value = country.name;
  } else {
    countryOption.value = "spain";
  }
  return countryOption;
});

export {
  allowDarkMode,
  captchaSiteKey,
  companyNumber,
  disableElitepvpersBacklink,
  discordFAQLink,
  discordLink,
  discordWidgetId,
  displayName,
  instagramLink,
  leagueShopMode,
  minimumCreditCardCheckout,
  minimumStripeCheckout,
  mythicAccountsLink,
  ownerLocation,
  ownerName,
  rankedValues,
  registeredLocation,
  skinThumbnailLink,
  supportEmail,
  tiktokLink,
  twitterLink,
  useSkinThumbail,
  website,
  countryOptions,
};
