import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import NoMythicSearchResults from "../../components/NoMythicSearchResult";
import NoSearchResults from "../../components/NoSearchResults";
import { displayName, leagueShopMode } from "../../constants";
import PriceContext from "../../contexts/PricesContext";
import SkinContext from "../../contexts/SkinContext";
import {
  getFilter,
  getIsOldStock,
  getLastPlayedDateObject,
  getProductTags,
  getThumbnail,
  rankToValue,
  divisionMap,
} from "../../utils";
import AuctionProductCard from "./AuctionProductCard";
import AuctionProductCardPlaceholder from "./AuctionProductCardPlaceholder";
import MarketplaceSidebar from "./MarketplaceSidebar";

const useQuery = () => new URLSearchParams(useLocation().search);

const Marketplace = () => {
  const query = useQuery();
  const [filter, setFilter] = useState(getFilter(query));
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [allProducts, setAllProducts] = useState({ products: [] });
  const [isLogged, setIsLogged] = useState(false);

  const { skinShards } = useContext(SkinContext);

  const { prices } = useContext(PriceContext);

  const showResults = useCallback(
    (allProducts, skinShards, filter) => {
      let output = [...allProducts];
      let interestedSkins = [];

      const mythicSkins = skinShards.reduce((filtered, skin) => {
        if (skin.tier === "MYTHIC") filtered.push(skin.id);
        return filtered;
      }, []);

      // Filter out mythic accounts if filter empty
      const isEmptyFilter = Object.entries(filter).every((value) => {
        return !value[1] || value[1]?.length === 0;
      });
      if (isEmptyFilter) {
        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                // const skins = o.product.skins.concat(
                //                 o.product.permanentSkins, o.product.ownedSkins
                //               )
                // if (skins.some((skin) => mythicSkins.includes(skin))) {
                //   return filtered;
                // }
                filtered.push(o);
                return filtered;
              }, [])
            : output;
      }

      // filter by product's id,
      // use case:
      // view Marketplace product by id, referred from 'buy account' page
      if (filter.productId) {
        output = output.filter(
          (o) => o.product.id === Number(filter.productId),
        );
      }

      // only show premium accounts when premium = true
      if (filter.premium) {
        output = output.filter((o) => o.product.isPremium);
      }

      // filter account types
      let accountType = filter.accountType || "any";
      if (accountType) {
        if (accountType === "unranked") {
          output = output.filter(
            (o) =>
              !o.product.isHandleveled &&
              o.product.rank?.toLowerCase() === accountType,
          );
        } else if (accountType === "handleveled") {
          output = output.filter((o) => o.product.isHandleveled);
        } else if (accountType === "ranked-ready") {
          output = output.filter(
            (o) => o.product.quickplayLosses + o.product.quickplayWins >= 10,
          );
        } else if (accountType === "ranked") {
          if (filter.rank) {
            output = output.filter((o) => {
              return (
                o.product.rank?.substring(0, 2) === filter.rank &&
                (filter.division
                  ? divisionMap(o.product.division) ===
                    parseInt(filter.division)
                  : true)
              );
            });
          } else {
            output = output.filter((o) => {
              return (
                !o.product.isHandleveled &&
                o.product.rank?.toLowerCase() !== "unranked"
              );
            });
          }
        }
      }
      if (filter.flashKey) {
        output = output.filter((o) => o.product.flashKey === filter.flashKey);
      }

      if (filter.region && filter.region.length > 0)
        output = output.filter((o) => filter.region.includes(o.product.region));
      if (filter.blueEssence)
        output = output.filter(
          (o) => o.product.blueEssence >= filter.blueEssence,
        );
      if (filter.oeStart || filter.oeEnd)
        output = output.filter((o) => {
          let isFiltered = true;
          if (filter.oeStart)
            isFiltered =
              isFiltered && o.product.orangeEssence >= filter.oeStart;
          if (filter.oeEnd)
            isFiltered = isFiltered && o.product.orangeEssence <= filter.oeEnd;
          return isFiltered;
        });
      if (filter.skinShard && filter.skinShard.length > 0) {
        interestedSkins = interestedSkins.concat(
          filter.skinShard.map((skin) => parseInt(skin)),
        );
        output = output.filter((o) => {
          let allSkins = o.product.skins.concat(
            o.product.ownedSkins,
            o.product.permanentSkins,
          );
          return filter.skinShard.every((skin) =>
            allSkins.includes(parseInt(skin)),
          );
        });
      }

      if (filter.champion && filter.champion.length > 0) {
        const championSkinIds = skinShards
          .filter((s) => filter.champion.includes(s.description))
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...championSkinIds];
        output = output.filter((o) => {
          const skins = o.product.skins.concat(
            o.product.ownedSkins,
            o.product.permanentSkins,
          );
          const count = skins.filter((skin) =>
            championSkinIds.includes(skin),
          ).length;
          return count >= 1;
        });
        output.sort((a, b) => {
          const skinsA = a.product.skins.concat(
            a.product.ownedSkins,
            a.product.permanentSkins,
          );
          const skinsB = b.product.skins.concat(
            b.product.ownedSkins,
            b.product.permanentSkins,
          );
          const matchedSkinsA = skinsA.filter((v) =>
            championSkinIds.includes(v),
          ).length;
          const matchedSkinsB = skinsB.filter((v) =>
            championSkinIds.includes(v),
          ).length;
          return matchedSkinsB - matchedSkinsA;
        });
      }

      if (filter.lane) {
        const laneSkinIds = skinShards
          .filter((s) =>
            filter.lane.toLowerCase().includes(s.lane?.toLowerCase()),
          )
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...laneSkinIds];

        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o.product.skins.concat(
                  o.product.ownedSkins,
                  o.product.permanentSkins,
                );
                let tempSkins = skins.filter((skin) =>
                  laneSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["laneSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;

        output.sort((a, b) => b.laneSkinCount - a.laneSkinCount);
      }

      if (filter.rankOrder) {
        if (filter.rankOrder === "LowToHigh") {
          output.sort((a, b) => {
            const rankA =
              (a.product.rank ? a.product.rank.substring(0, 2) : "") +
              divisionMap(a.product.division);
            const rankB =
              (b.product.rank ? b.product.rank.substring(0, 2) : "") +
              divisionMap(b.product.division);
            const valueA = rankToValue(rankA);
            const valueB = rankToValue(rankB);
            if (valueA < valueB) {
              return 1;
            } else if (valueA > valueB) {
              return -1;
            }
            return 0;
          });
        }

        if (filter.rankOrder === "HighToLow") {
          output.sort((a, b) => {
            const rankA =
              (a.product.rank ? a.product.rank.substring(0, 2) : "") +
              divisionMap(a.product.division);
            const rankB =
              (b.product.rank ? b.product.rank.substring(0, 2) : "") +
              divisionMap(b.product.division);
            const valueA = rankToValue(rankA);
            const valueB = rankToValue(rankB);
            if (valueA < valueB) {
              return -1;
            } else if (valueA > valueB) {
              return 1;
            }
            return 0;
          });
        }
      }

      if (filter.role) {
        const roleSkinIds = skinShards
          .filter((s) =>
            s.role?.toLowerCase().includes(filter.role.toLowerCase()),
          )
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...roleSkinIds];

        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o.product.skins.concat(
                  o.product.ownedSkins,
                  o.product.permanentSkins,
                );
                let tempSkins = skins.filter((skin) =>
                  roleSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["roleSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;
        output.sort((a, b) => b.roleSkinCount - a.roleSkinCount);
      }

      if (filter.accountTier) {
        const accountSkinIds = skinShards
          .filter((s) => s.tier === filter.accountTier)
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...accountSkinIds];
        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o.product.skins.concat(
                  o.product.ownedSkins,
                  o.product.permanentSkins,
                );
                const tempSkins = skins.filter((skin) =>
                  accountSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["accountSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;
      }

      //Slice 100 output and add price, interested skins and tags
      output = output.slice(0, 100).map((o) => {
        //   // Convert array product to dict
        //   const [
        //     id,
        //     region,
        //     discreteBlueEssence,
        //     orangeEssence,
        //     skins,
        //     permanentSkins,
        //     ownedSkins,
        //     discreteLevel,
        //     isHandleveled,
        //     rank,
        //     division,
        //     flashKey,
        //     soloWins,
        //     soloLosses,
        //     isPremium,
        //   ] = o;

        //   const product = {
        //     id,
        //     region,
        //     discreteBlueEssence,
        //     orangeEssence,
        //     skins,
        //     permanentSkins,
        //     ownedSkins,
        //     discreteLevel,
        //     isHandleveled,
        //     rank,
        //     division,
        //     flashKey,
        //     soloWins,
        //     soloLosses,
        //     isPremium,
        //   };

        // important to convert empty string to null
        // because it raises errors when sending data to drf
        // when creating cart item
        if (o.product.soloWins === "") o.product.soloWins = null;
        if (o.product.soloLosses === "") o.product.soloLosses = null;

        if (!o.product?.quickplayWins) o.product.quickplayWins = 0;
        if (!o.product?.quickplayLosses) o.product.quickplayLosses = 0;

        const productSkins = [
          ...o.product.skins,
          ...o.product.permanentSkins,
          ...o.product.ownedSkins,
        ];
        const mythicCount = productSkins.filter((skin) =>
          mythicSkins.includes(skin),
        ).length;

        // product["price"] = getPrice(
        //   product.discreteBlueEssence,
        //   product.isHandleveled,
        //   product.rank,
        //   product.division,
        //   product.isPremium,
        //   mythicCount,
        //   prices,
        // );
        o.product["isOldStock"] = getIsOldStock(
          getLastPlayedDateObject(o.product.dateLastPlayed?.slice(0, 10)),
          prices,
        );
        o.product["isAuction"] = true;
        const [tags, label_tags] = getProductTags(
          o.product,
          filter,
          mythicCount,
          prices,
        );
        o.product["tags"] = tags;
        o.product["label_tags"] = label_tags;

        const thumbnailSkins =
          interestedSkins.length > 0
            ? productSkins.filter((skin) => interestedSkins.includes(skin))
            : productSkins;

        const [thumbnail, thumbnailSkin] = getThumbnail(
          skinShards,
          thumbnailSkins,
        );
        o.product["thumbnail"] = thumbnail;
        o.product["relevantSkins"] = [...interestedSkins, ...[thumbnailSkin]];
        return o;
      });
      setProducts(output);
    },
    [prices],
  );

  useEffect(() => {
    const load = async (signal) => {
      try {
        setFetching(true);
        const product_id = filter.productId || "";
        const url_query = product_id ? `?product_id=${product_id}` : "";
        const products_pathname = "/api/marketplace/products/" + url_query;

        const products_response = await fetch(products_pathname, { signal });

        if (products_response.ok) {
          const output = await products_response.json();
          output.products.sort((a, b) => {
            const dateA = new Date(a.endDate);
            const dateB = new Date(b.endDate);
            return dateA - dateB;
          });
          setAllProducts(output);
        }
        setFetching(false);
      } catch (reason) {
        if (reason.name === "AbortError") return;
        setFetching(false);
      }
    };

    document.title = `${displayName} | Marketplace | Buy and Sell League of Legends Accounts`;
    const controller = new AbortController();
    load(controller.signal);
    return () => controller.abort();
  }, [filter.productId]);

  useEffect(() => {
    const fetchData = async (signal) => {
      try {
        const response = await fetch("/api/auth/user/", { signal });
        if (response.ok) {
          setIsLogged(true);
        }
      } catch (reason) {
        console.log(reason);
      }
    };
    const controller = new AbortController();
    fetchData(controller.signal);
    return () => controller.abort();
  }, []);

  useEffect(() => {
    setLoading(true);
    showResults(allProducts.products, skinShards, filter);
    setLoading(false);
  }, [allProducts, skinShards, filter, showResults]);

  const renderProducts = () => (
    <div className="product-card-group">
      {products.map((product, index) => (
        <div
          style={{ display: "flex", alignSelf: "stretch" }}
          key={product.product.id}
          data-aos="fade-up"
          data-aos-duration={500}
        >
          <AuctionProductCard
            product={product}
            userId={allProducts.userId}
            isLogged={isLogged}
            alt="product-image"
          />
        </div>
      ))}
    </div>
  );

  const shouldReferToLolaccshop = () => {
    const newAccountType = query.getAll("account-tier");
    const isSmurfSkins = leagueShopMode === "smurfskins";

    return newAccountType.includes("MYTHIC") && isSmurfSkins;
  };

  return (
    <Segment basic className="buy-account-page">
      <div className="buy-account-container">
        <div className="sidebar">
          <MarketplaceSidebar
            onChange={(filter) => setFilter(filter)}
            path={"/marketplace/"}
          />
        </div>

        {loading || fetching ? (
          <div className="product-card-group">
            <AuctionProductCardPlaceholder />
            <AuctionProductCardPlaceholder />
            <AuctionProductCardPlaceholder />
            <AuctionProductCardPlaceholder />
            <AuctionProductCardPlaceholder />
            <AuctionProductCardPlaceholder />
            <AuctionProductCardPlaceholder />
            <AuctionProductCardPlaceholder />
          </div>
        ) : products.length === 0 ? (
          shouldReferToLolaccshop() ? (
            <NoMythicSearchResults />
          ) : (
            <NoSearchResults />
          )
        ) : (
          renderProducts()
        )}
      </div>
    </Segment>
  );
};

export default Marketplace;
